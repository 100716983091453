import ApiService from '@/api/http/ApiService';
import { Company } from '@/interfaces/models/Company';
import axios from 'axios';
export class CompanyApiService extends ApiService<any> {
  constructor() {
    super('dnd/company', 'v1');
  }

  public getCompanies(data: { params: any }) {
    return axios.get(`${this.getBaseUrl()}/`, { params: data.params });
  }

  public getCompaniesByVenue(data: { id: string, params: any }) {
    return axios.get(`${this.getBaseUrl()}/venue/${data.id}`, { params: data.params });
  }

  public storeCompany(data: Partial<Company>) {
    return axios.post(`${this.getBaseUrl()}/`, data);
  }

  public checkUnique(data: any) {
    return axios.post(`${this.getBaseUrl()}/unique/company-code`, data);
  }

  public destroyCompany(param: { id: string }) {
    return axios.delete(`${this.getBaseUrl()}/${param.id}`);
  }
}
