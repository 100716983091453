import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import store from '@/stores';
import { DialogType } from '@/enums/DialogType';
import { Permission } from '@/enums/Permission';
import { can, hasAnyPermission, isAuthorized, isCustomerGroup } from '@/util/helper';
import { CustomerGroup } from '@/enums/CustomerGroup';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'start',
    component: () => import(/* webpackChunkName: "start" */ '../views/Start.vue'),
  },
  {
    path: '/home',
    name: 'home.index',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "auth.forgotPassword" */ '../views/auth/ForgotPassword.vue'),
  },
  {
    path: '/reset/:token',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "auth.resetPassword" */ '../views/auth/ResetPassword.vue'),
  },
  {
    path: '/register/:customerGroup?',
    name: 'register.index',
    component: () => import(/* webpackChunkName: "register.index" */ '../views/auth/Register.vue'),
  },
  {
    path: '/register/venue',
    name: 'register.venue',
    props: true,
    component: () => import(/* webpackChunkName: "register.venue" */ '../views/auth/RegisterVenue.vue'),
  },
  {
    path: '/register/success',
    name: 'register.success',
    props: true,
    component: () => import(/* webpackChunkName: "register.sucess" */ '../views/auth/RegisterSuccess.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard.index',
    meta: {
      can: Permission.STATISTICS_OVERVIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "dashboard.index" */ '../views/Dashboard.vue'),
  },
  {
    path: '/venue',
    name: 'venue.index',
    meta: {
      can: Permission.VENUE_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "venue.index" */ '../views/venue/Venue.vue'),
  },
  {
    path: '/venue/create',
    name: 'venue.create',
    meta: {
      can: Permission.VENUE_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "venue.create" */ '../views/venue/EditVenue.vue'),
  },
  {
    path: '/venue/create/simple',
    name: 'venue.createSimple',
    meta: {
      can: Permission.VENUE_CREATE_SIMPLE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "venue.createSimple" */ '../views/venue/CreateSimpleVenue.vue'),
  },
  {
    path: '/venue/:id/panic',
    name: 'venue.panic',
    meta: {
      can: Permission.VENUE_PANIC,
      auth: true,
    },
    props: {
      default: true,
    },
    component: () => import(/* webpackChunkName: "venue.panic" */ '../views/venue/VenuePanic.vue'),
  },
  {
    path: '/venue/:id/edit',
    name: 'venue.edit',
    meta: {
      can: Permission.VENUE_UPDATE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "venue.edit" */ '../views/venue/EditVenue.vue'),
  },
  {
    path: '/venue/:id/migrate',
    name: 'venue.migrate',
    meta: {
      canAny: [
        Permission.VENUE_MIGRATE_DEV_TO_DEV,
        Permission.VENUE_MIGRATE_DEV_TO_PROD,
        Permission.VENUE_MIGRATE_PROD_TO_PROD,
      ],
      auth: true,
    },
    props: {
      default: true,
    },
    component: () => import(/* webpackChunkName: "venue.migrate" */ '../views/venue/VenueMigration.vue'),
  },

  {
    path: '/venue/:id/sync',
    name: 'venue.sync',
    meta: {
      can: Permission.VENUE_SYNC_START,
      auth: true,
    },
    props: {
      default: true,
    },
    component: () => import(/* webpackChunkName: "venue.sync" */ '../views/venue/VenueSync.vue'),
  },
  {
    path: '/venue/:id/copy',
    name: 'venue.copy',
    meta: {
      can: Permission.ADMIN,
      auth: true,
    },
    props: {
      default: true,
    },
    component: () => import(/* webpackChunkName: "venue.copy" */ '../views/venue/CopyVenue.vue'),
  },
  {
    path: '/venue/foodcard/copy',
    name: 'venue.copyFoodcard',
    meta: {
      can: Permission.ADMIN,
      auth: true,
    },
    props: {
      default: true,
    },
    component: () => import(/* webpackChunkName: "venue.copyFoodcard" */ '../views/venue/CopyFoodcard.vue'),
  },
  {
    path: '/venue/:id/edit/simple',
    name: 'venue.editSimple',
    meta: {
      can: Permission.VENUE_UPDATE_SIMPLE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "venue.editSimple" */ '../views/venue/EditSimpleVenue.vue'),
  },
  {
    path: '/venue/:id/verify',
    name: 'venue.verify',
    meta: {
      can: Permission.VENUE_VERIFY,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "venue.verify" */ '../views/venue/VenueVerification.vue'),
  },
  {
    path: '/venue/:id/publish',
    name: 'venue.publish',
    meta: {
      can: Permission.VENUE_PUBLISH,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "venue.publish" */ '../views/venue/VenuePublication.vue'),
  },
  {
    path: '/venueLabel',
    name: 'venueLabel.index',
    meta: {
      can: Permission.LABEL_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "venueLabel.index" */ '../views/venueLabel/VenueLabel.vue'),
  },
  {
    path: '/venueLabel/create',
    name: 'venueLabel.create',
    meta: {
      can: Permission.LABEL_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "venueLabel.create" */ '../views/venueLabel/EditVenueLabel.vue'),
  },
  {
    path: '/venueLabel/:id/edit',
    name: 'venueLabel.edit',
    meta: {
      can: Permission.LABEL_UPDATE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "venueLabel.edit" */ '../views/venueLabel/EditVenueLabel.vue'),
  },
  {
    path: '/company',
    name: 'company.index',
    meta: {
      can: Permission.DND_COMPANY_VIEW,
      isCustomerGroup: CustomerGroup.DeanAndDavid,
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "membership.index" */ '../views/company/Company.vue'),
  },
  {
    path: '/company/create',
    name: 'company.create',
    meta: {
      can: Permission.DND_COMPANY_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "membership.create" */ '../views/company/EditCompany.vue'),
  },
  {
    path: '/company/:id/edit',
    name: 'company.edit',
    meta: {
      can: Permission.DND_COMPANY_UPDATE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "membership.create" */ '../views/company/EditCompany.vue'),
  },
  {
    path: '/productCombos',
    name: 'productCombos.index',
    meta: {
      can: Permission.VENUE_UPDATE,
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "productCombos.index" */ '../views/productCombos/ProductCombos.vue'),
  },
  {
    path: '/table',
    name: 'table.index',
    meta: {
      auth: true,
      useVenue: true,
      can: Permission.TABLE_VIEW,
    },
    component: () => import(/* webpackChunkName: "table.index" */ '../views/table/Table.vue'),
  },
  {
    path: '/table/create',
    name: 'table.create',
    meta: {
      useVenue: true,
      can: Permission.TABLE_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "table.create" */ '../views/table/EditTable.vue'),
  },
  {
    path: '/table/:id/edit',
    name: 'table.edit',
    props: {
      default: true,
      editing: true,
    },
    meta: {
      can: Permission.TABLE_UPDATE,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "table.edit" */ '../views/table/EditTable.vue'),
  },
  {
    path: '/subscriptionCategories',
    name: 'subscriptionCategories.index',
    meta: {
      can: Permission.CATEGORY_VIEW,
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "subscriptionCategories.index" */ '../views/subscriptionCategories/SubscriptionCategories.vue'
      ),
  },
  {
    path: '/subscriptionCategories/create',
    name: 'subscriptionCategories.create',
    meta: {
      can: Permission.CATEGORY_CREATE,
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "subscriptionCategories.create" */ '../views/subscriptionCategories/EditSubscriptionCategories.vue'
      ),
  },
  {
    path: '/subscriptionCategories/:id/edit',
    name: 'subscriptionCategories.edit',
    meta: {
      can: Permission.CATEGORY_UPDATE,
      auth: true,
    },
    props: {
      editing: true,
      default: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "subscriptionCategories.edit" */ '../views/subscriptionCategories/EditSubscriptionCategories.vue'
      ),
  },
  {
    path: '/subscription',
    name: 'subscription.index',
    meta: {
      can: Permission.CATEGORY_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "subscription.index" */ '../views/subscription/Subscription.vue'),
  },
  {
    path: '/subscription/create',
    name: 'subscription.create',
    meta: {
      can: Permission.CATEGORY_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "subscription.create" */ '../views/subscription/EditSubscription.vue'),
  },
  {
    path: '/subscription/:id/edit',
    name: 'subscription.edit',
    meta: {
      can: Permission.CATEGORY_UPDATE,
      auth: true,
    },
    props: {
      editing: true,
      default: true,
    },
    component: () => import(/* webpackChunkName: "subscription.edit" */ '../views/subscription/EditSubscription.vue'),
  },
  {
    path: '/membership',
    name: 'membership.index',
    meta: {
      can: Permission.MEMBERSHIP_LEVELS_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "membership.index" */ '../views/membership/Membership.vue'),
  },
  {
    path: '/membership/create',
    name: 'membership.create',
    meta: {
      can: Permission.MEMBERSHIP_LEVELS_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "membership.create" */ '../views/membership/EditMembership.vue'),
  },
  {
    path: '/membership/:id/edit',
    name: 'membership.edit',
    meta: {
      can: Permission.MEMBERSHIP_LEVELS_UPDATE,
      auth: true,
    },
    props: {
      editing: true,
      default: true,
    },
    component: () => import(/* webpackChunkName: "membership.edit" */ '../views/membership/EditMembership.vue'),
  },

  {
    path: '/category',
    name: 'category.index',
    meta: {
      can: Permission.CATEGORY_VIEW,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "category.index" */ '../views/category/Category.vue'),
  },
  {
    path: '/category/create',
    name: 'category.create',
    meta: {
      can: Permission.CATEGORY_CREATE,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "category.create" */ '../views/category/EditCategory.vue'),
  },
  {
    path: '/category/:id/edit',
    name: 'category.edit',
    meta: {
      can: Permission.CATEGORY_UPDATE,
      useVenue: true,
      auth: true,
    },
    props: {
      editing: true,
      default: true,
    },
    component: () => import(/* webpackChunkName: "category.edit" */ '../views/category/EditCategory.vue'),
  },
  {
    path: '/article',
    name: 'article.index',
    meta: {
      can: Permission.ARTICLE_VIEW,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "article.index" */ '../views/article/Article.vue'),
  },
  {
    path: '/article/create',
    name: 'article.create',
    meta: {
      can: Permission.ARTICLE_CREATE,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "article.create" */ '../views/article/EditArticle.vue'),
  },
  {
    path: '/article/:category/:id/edit',
    name: 'article.edit',
    meta: {
      can: Permission.ARTICLE_UPDATE,
      useVenue: true,
      auth: true,
    },
    props: {
      editing: true,
      default: true,
    },
    component: () => import(/* webpackChunkName: "article.edit" */ '../views/article/EditArticle.vue'),
  },
  {
    path: '/article/:category/:id/edit/simple',
    name: 'article.editSimple',
    meta: {
      can: Permission.ARTICLE_UPDATE_SIMPLE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "article.editSimple" */ '../views/article/EditSimpleArticle.vue'),
  },
  {
    path: '/option',
    name: 'option.index',
    meta: {
      can: Permission.OPTION_VIEW,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "option.index" */ '../views/option/Option.vue'),
  },
  {
    path: '/option/create',
    name: 'option.create',
    meta: {
      can: Permission.OPTION_CREATE,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "option.create" */ '../views/option/EditOption.vue'),
  },
  {
    path: '/option/:id/edit',
    name: 'option.edit',
    meta: {
      can: Permission.OPTION_UPDATE,
      useVenue: true,
      auth: true,
    },
    props: {
      editing: true,
      default: true,
    },
    component: () => import(/* webpackChunkName: "option.edit" */ '../views/option/EditOption.vue'),
  },
  {
    path: '/option/:id/edit/simple',
    name: 'option.editSimple',
    meta: {
      can: Permission.OPTION_ARTICLE_UPDATE_SIMPLE,
      useVenue: true,
      auth: true,
    },
    props: {
      editing: true,
      default: true,
    },
    component: () => import(/* webpackChunkName: "option.editSimple" */ '../views/option/EditSimpleOption.vue'),
  },
  {
    path: '/article-manager',
    name: 'articleManager.index',
    meta: {
      can: Permission.ARTICLE_VIEW,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "option.index" */ '../views/articleManager/ArticleManager.vue'),
  },
  {
    path: '/tag',
    name: 'tag.index',
    meta: {
      can: Permission.TAG_VIEW,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "tag.index" */ '../views/tag/Tag.vue'),
  },
  {
    path: '/screensaver',
    name: 'screensaver.index',
    meta: {
      auth: true,
      can: Permission.SCREENSAVER_VIEW,
    },
    component: () => import(/* webpackChunkName: "screensaver.index" */ '../views/screensaver/Screensaver.vue'),
  },
  {
    path: '/screensaver/:id/edit',
    name: 'screensaver.edit',
    meta: {
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "screensaver.edit" */ '../views/screensaver/EditScreensaver.vue'),
  },
  {
    path: '/screensaver/create',
    name: 'screensaver.create',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "screensaver.create" */ '../views/screensaver/EditScreensaver.vue'),
  },
  {
    path: '/banner',
    name: 'banner.index',
    meta: {
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "banner.index" */ '../views/banner/Banner.vue'),
  },
  {
    path: '/banner/create',
    name: 'banner.create',
    meta: {
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "banner.create" */ '../views/banner/EditBanner.vue'),
  },
  {
    path: '/banner/:id/edit',
    name: 'banner.edit',
    meta: {
      auth: true,
      useVenue: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "banner.edit" */ '../views/banner/EditBanner.vue'),
  },
  {
    path: '/tag/create',
    name: 'tag.create',
    meta: {
      can: Permission.TAG_CREATE,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "tag.create" */ '../views/tag/EditTag.vue'),
  },
  {
    path: '/tag/:id/edit',
    name: 'tag.edit',
    meta: {
      can: Permission.TAG_UPDATE,
      useVenue: true,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "tag.edit" */ '../views/tag/EditTag.vue'),
  },

  {
    path: '/information',
    name: 'information.index',
    meta: {
      can: Permission.INFORMATION_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "information.index" */ '../views/information/Information.vue'),
  },
  {
    path: '/information/create',
    name: 'information.create',
    meta: {
      can: Permission.INFORMATION_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "information.create" */ '../views/information/EditInformation.vue'),
  },
  {
    path: '/information/:id/edit',
    name: 'information.edit',
    meta: {
      can: Permission.INFORMATION_UPDATE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "information.edit" */ '../views/information/EditInformation.vue'),
  },

  {
    path: '/client',
    name: 'client.index',
    meta: {
      can: Permission.CLIENT_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "client.index" */ '../views/client/Client.vue'),
  },
  {
    path: '/client/create',
    name: 'client.create',
    meta: {
      can: Permission.CLIENT_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "client.create" */ '../views/client/EditClient.vue'),
  },
  {
    path: '/client/:id/edit',
    name: 'client.edit',
    meta: {
      can: Permission.CLIENT_UPDATE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "client.edit" */ '../views/client/EditClient.vue'),
  },
  {
    path: '/constrain',
    name: 'constrain.index',
    meta: {
      can: Permission.CONSTRAIN_VIEW,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "constrain.index" */ '../views/constrain/Constrain.vue'),
  },
  {
    path: '/constrain/create',
    name: 'constrain.create',
    meta: {
      can: Permission.CONSTRAIN_CREATE,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "constrain.create" */ '../views/constrain/EditConstrain.vue'),
  },
  {
    path: '/constrain/:id/edit',
    name: 'constrain.edit',
    meta: {
      can: Permission.CONSTRAIN_UPDATE,
      useVenue: true,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "constrain.edit" */ '../views/constrain/EditConstrain.vue'),
  },
  {
    path: '/printGroups',
    name: 'printGroups.index',
    meta: {
      can: Permission.CONSTRAIN_VIEW,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "printGroups.index" */ '../views/printGroups/printGroups.vue'),
  },
  {
    path: '/printGroups/create',
    name: 'printGroups.create',
    meta: {
      can: Permission.PRINTGROUPS_CREATE,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "printGroups.create" */ '../views/printGroups/EditPrintGroups.vue'),
  },
  {
    path: '/printGroups/:id/edit',
    name: 'printGroups.edit',
    meta: {
      can: Permission.PRINTGROUPS_UPDATE,
      useVenue: true,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "printGroups.edit" */ '../views/printGroups/EditPrintGroups.vue'),
  },
  {
    path: '/workflow',
    name: 'workflow.index',
    meta: {
      can: Permission.WORKFLOW_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "workflow.index" */ '../views/workflow/Workflow.vue'),
  },
  {
    path: '/workflow/import',
    name: 'workflow.import',
    meta: {
      can: Permission.WORKFLOW_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "workflow.import" */ '../views/workflow/ImportWorkflow.vue'),
  },
  {
    path: '/promo',
    name: 'promo.index',
    meta: {
      can: Permission.PROMO_CODE_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "promo.index" */ '../views/promo/PromoCode.vue'),
  },
  {
    path: '/promo/export',
    name: 'promo.export',
    meta: {
      canAny: [
        Permission.PROMO_CODE_EXPORT,
        Permission.PROMO_CODE_EXPORT_SUMMARY,
        Permission.PROMO_CODE_EXPORT_COUNT,
        Permission.PROMO_CODE_EXPORT_OVERVIEW,
      ],
      auth: true,
    },
    component: () => import(/* webpackChunkName: "promo.export" */ '../views/promo/ExportPromoCode.vue'),
  },
  {
    path: '/promo/create',
    name: 'promo.create',
    meta: {
      canAny: [Permission.PROMO_CODE_CREATE, Permission.PROMO_CODE_CREATE_OWN],
      auth: true,
    },
    component: () => import(/* webpackChunkName: "promo.create" */ '../views/promo/EditPromoCode.vue'),
  },
  {
    path: '/promo/create/multiple',
    name: 'promo.createMultiple',
    meta: {
      canAny: [Permission.PROMO_CODE_CREATE, Permission.PROMO_CODE_CREATE_OWN],
      auth: true,
    },
    props: {
      default: true,
      multiple: true,
    },
    component: () => import(/* webpackChunkName: "promo.create" */ '../views/promo/EditPromoCode.vue'),
  },
  {
    path: '/promo/create/teedeli',
    name: 'promo.createTeedeli',
    meta: {
      canAny: [Permission.PROMO_CODE_CREATE, Permission.PROMO_CODE_CREATE_OWN],
      auth: true,
    },
    props: {
      teedeli: true,
    },
    component: () => import(/* webpackChunkName: "promo.create" */ '../views/promo/EditPromoCode.vue'),
  },
  {
    path: '/promo/:id/edit',
    name: 'promo.edit',
    meta: {
      can: Permission.PROMO_CODE_UPDATE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "promo.edit" */ '../views/promo/EditPromoCode.vue'),
  },
  {
    path: '/promo/disable',
    name: 'promo.disable',
    meta: {
      can: Permission.PROMO_CODE_DISABLE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "promo.disable" */ '../views/promo/DisablePromoCode.vue'),
  },
  {
    path: '/cupCode',
    name: 'cupCode.index',
    meta: {
      can: Permission.ADMIN,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "promo.index" */ '../views/cupCode/CupCode.vue'),
  },
  {
    path: '/cupCode/create',
    name: 'cupCode.create',
    meta: {
      canAny: [Permission.ADMIN],
      auth: true,
    },
    component: () => import(/* webpackChunkName: "promo.create" */ '../views/cupCode/EditCupCode.vue'),
  },
  {
    path: '/cupCode/:id/editt',
    name: 'cupCode.edit',
    meta: {
      canAny: [Permission.ADMIN],
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "promo.create" */ '../views/cupCode/EditCupCode.vue'),
  },
  {
    path: '/coupon',
    name: 'coupon.index',
    meta: {
      can: Permission.COUPON_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "promo.index" */ '../views/coupon/Coupon.vue'),
  },
  {
    path: '/coupon/export',
    name: 'coupon.export',
    meta: {
      canAny: [
        Permission.COUPON_EXPORT,
        Permission.COUPON_EXPORT_SUMMARY,
        Permission.COUPON_EXPORT_COUNT,
        Permission.COUPON_EXPORT_OVERVIEW,
      ],
      auth: true,
    },
    component: () => import(/* webpackChunkName: "promo.export" */ '../views/coupon/ExportCoupon.vue'),
  },
  {
    path: '/coupon/create',
    name: 'coupon.create',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "promo.create" */ '../views/coupon/EditCoupon.vue'),
  },
  {
    path: '/coupon/create/multiple',
    name: 'coupon.createMultiple',
    meta: {
      canAny: [Permission.COUPON_CREATE, Permission.COUPON_CREATE_OWN],
      auth: true,
    },
    props: {
      default: true,
      multiple: true,
    },
    component: () => import(/* webpackChunkName: "promo.create" */ '../views/coupon/EditCoupon.vue'),
  },
  {
    path: '/coupon/:id/edit',
    name: 'coupon.edit',
    meta: {
      can: Permission.COUPON_UPDATE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "promo.edit" */ '../views/coupon/EditCoupon.vue'),
  },
  {
    path: '/coupon/disable',
    name: 'coupon.disable',
    meta: {
      can: Permission.COUPON_DISABLE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "promo.disable" */ '../views/coupon/DisableCoupon.vue'),
  },
  {
    path: '/customer',
    name: 'customer.index',
    meta: {
      can: Permission.CUSTOMER_VIEW,
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "customer.index" */ '../views/customer/Customer.vue'),
  },
  {
    path: '/customer/create',
    name: 'customer.create',
    meta: {
      can: Permission.CUSTOMER_CREATE,
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "customer.create" */ '../views/customer/EditCustomer.vue'),
  },
  {
    path: '/customer/:id/edit',
    name: 'customer.edit',
    meta: {
      can: Permission.CUSTOMER_UPDATE,
      auth: true,
      useVenue: true,
    },
    props: {
      editing: true,
      default: true,
    },
    component: () => import(/* webpackChunkName: "customer.edit" */ '../views/customer/EditCustomer.vue'),
  },
  {
    path: '/foodcard',
    name: 'foodcard.index',
    meta: {
      can: Permission.FOODCARD_IMPORT,
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "article.import" */ '../views/article/ImportFoodcard.vue'),
  },
  {
    path: '/printer',
    name: 'printer.index',
    meta: {
      can: Permission.PRINTER_VIEW,
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "printer.index" */ '../views/printer/Printer.vue'),
  },
  {
    path: '/printer/edit',
    name: 'printer.edit',
    props: true,
    meta: {
      can: Permission.PRINTER_UPDATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "printer.edit" */ '../views/printer/EditPrinter.vue'),
  },
  {
    path: '/role',
    name: 'role.index',
    meta: {
      can: Permission.ROLE_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "role.index" */ '../views/role/Role.vue'),
  },
  {
    path: '/role/create',
    name: 'role.create',
    meta: {
      can: Permission.ROLE_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "role.create" */ '../views/role/EditRole.vue'),
  },
  {
    path: '/role/:id/edit',
    name: 'role.edit',
    meta: {
      can: Permission.ROLE_UPDATE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "role.edit" */ '../views/role/EditRole.vue'),
  },
  {
    path: '/customerGroupProprty',
    name: 'customerGroupProprty.index',
    meta: {
      can: Permission.ADMIN,
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "role.index" */ '../views/customerGroupProperty/CustomerGroupProperty.vue'),
  },
  {
    path: '/customerGroupProprty/create',
    name: 'customerGroupProprty.create',
    meta: {
      can: Permission.ADMIN,
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "role.create" */ '../views/customerGroupProperty/EditCustomerGroupProperty.vue'),
  },
  {
    path: '/customerGroupProprty/:id/edit',
    name: 'customerGroupProprty.edit',
    meta: {
      can: Permission.ADMIN,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () =>
      import(/* webpackChunkName: "role.edit" */ '../views/customerGroupProperty/EditCustomerGroupProperty.vue'),
  },
  {
    path: '/user',
    name: 'user.index',
    meta: {
      can: Permission.USER_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "user.index" */ '../views/user/User.vue'),
  },
  {
    path: '/user/create',
    name: 'user.create',
    meta: {
      can: Permission.USER_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "user.create" */ '../views/user/EditUser.vue'),
  },
  {
    path: '/user/create/simple',
    name: 'user.createSimple',
    meta: {
      can: Permission.USER_CREATE_RESTRICTED,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "user.createRestricted" */ '../views/user/EditSimpleUser.vue'),
  },
  {
    path: '/user/:id/edit',
    name: 'user.edit',
    meta: {
      can: Permission.USER_UPDATE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "user.edit" */ '../views/user/EditUser.vue'),
  },
  {
    path: '/user/:id/edit/simple',
    name: 'user.editSimple',
    meta: {
      can: Permission.USER_UPDATE_RESTRICTED,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "user.editSimple" */ '../views/user/EditSimpleUser.vue'),
  },
  {
    path: '/statistics',
    name: 'statistics.index',
    meta: {
      can: Permission.STATISTICS_VIEW,
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "statistics.index" */ '../views/Statistics.vue'),
  },
  {
    path: '/sales',
    name: 'sales.index',
    meta: {
      can: Permission.SALES_VIEW,
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "sales.index" */ '../views/Sales.vue'),
  },
  {
    path: '/sales-overview',
    name: 'salesOverview.index',
    meta: {
      can: Permission.SALES_OVERVIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "sales.index" */ '../views/salesOverview/SalesOverview.vue'),
  },
  {
    path: '/payments',
    name: 'payment.index',
    meta: {
      can: Permission.PAYMENT_VIEW,
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "payment.index" */ '../views/payment/Payment.vue'),
  },
  {
    path: '/provision',
    name: 'provision.index',
    meta: {
      can: Permission.PROVISION_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "provision.index" */ '../views/provision/Provision.vue'),
  },
  {
    path: '/inventory',
    name: 'inventory.index',
    meta: {
      can: Permission.INVENTORY_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "provision.index" */ '../views/inventory/Inventory.vue'),
  },
  {
    path: '/inventory/create',
    name: 'inventory.create',
    meta: {
      can: Permission.INVENTORY_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "provision.index" */ '../views/inventory/EditInventory.vue'),
  },
  {
    path: '/inventory/:id/edit',
    name: 'inventory.edit',
    meta: {
      can: Permission.INVENTORY_UPDATE,
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () => import(/* webpackChunkName: "provision.index" */ '../views/inventory/EditInventory.vue'),
  },
  {
    path: '/analytics',
    name: 'analytics.index',
    meta: {
      can: Permission.ANALYTICS_VIEW,
      auth: true,
      useVenue: true,
    },
    component: () => import(/* webpackChunkName: "analytics.index" */ '../views/Analytics.vue'),
  },
  {
    path: '/maintenance',
    name: 'maintenance.index',
    meta: {
      can: Permission.ADMIN,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "maintenance.index" */ '../views/Maintenance.vue'),
  },
  {
    path: '/employeeNotification',
    name: 'employeeNotification.index',
    meta: {
      can: Permission.ADMIN,
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "maintenance.index" */ '../views/employeeNotification/EmployeeNotification.vue'),
  },
  {
    path: '/employeeNotification/create',
    name: 'employeeNotification.create',
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "screensaver.create" */ '../views/employeeNotification/EditEmployeeNotification.vue'),
  },
  {
    path: '/loyaltyPrograms',
    name: 'loyaltyPrograms.index',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "membership.index" */ '../views/loyaltyPrograms/LoyaltyPrograms.vue'),
  },
  {
    path: '/loyaltyPrograms/create',
    name: 'loyaltyPrograms.create',
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "membership.create" */ '../views/loyaltyPrograms/EditLoyaltyPrograms.vue'),
  },
  {
    path: '/loyaltyPrograms/:id/edit',
    name: 'loyaltyPrograms.edit',
    meta: {
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () =>
      import(/* webpackChunkName: "membership.create" */ '../views/loyaltyPrograms/EditLoyaltyPrograms.vue'),
  },
  {
    path: '/billing',
    name: 'billing.index',
    meta: {
      canAny: [Permission.INVOICE_VENUE, Permission.INVOICE_SUMMARY, Permission.ORDER_EXPORT, Permission.BILLING_VIEW],
      auth: true,
    },
    component: () => import(/* webpackChunkName: "billing.index" */ '../views/Billing.vue'),
  },
  {
    path: '/userExport',
    name: 'userExport.index',
    meta: {
      canAny: [Permission.INVOICE_VENUE, Permission.INVOICE_SUMMARY, Permission.ORDER_EXPORT],
      auth: true,
    },
    component: () => import(/* webpackChunkName: "userExport.index" */ '../views/UserExport.vue'),
  },
  {
    path: '/activityLogExport',
    name: 'activityLogExport.index',
    meta: {
      canAny: [Permission.ADMIN],
      auth: true,
    },
    component: () => import(/* webpackChunkName: "userExport.index" */ '../views/ActivityLogExport.vue'),
  },
  {
    path: '/sepa',
    name: 'sepa.index',
    meta: {
      can: Permission.VENUE_SEPA_CREATE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "sepa.index" */ '../views/sepa/SepaSettings.vue'),
  },
  {
    path: '/translation',
    name: 'translation.index',
    meta: {
      can: Permission.FOODCARD_IMPORT,
      useVenue: true,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "translation.index" */ '../views/translation/Translation.vue'),
  },
  {
    path: '/recommendation',
    name: 'recommendation.index',
    meta: {
      can: Permission.RECOMMENDATIONS_VIEW,
      useVenue: true,
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "recommendation.index" */ '../views/recommendation/Recommendation.vue'),
  },
  {
    path: '/receipts',
    name: 'receipt.index',
    meta: {
      can: Permission.RECEIPT_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "receipt.index" */ '../views/receipt/Receipt.vue'),
  },
  {
    path: '/reporting',
    name: 'reporting.index',
    meta: {
      can: Permission.REPORTING_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "reporting.index" */ '../views/Reporting.vue'),
  },
  {
    path: '/tips',
    name: 'tips.index',
    meta: {
      can: Permission.BILLING_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "tips.index" */ '../views/Tips.vue'),
  },
  {
    path: '/eposJobs',
    name: 'eposJobs.index',
    meta: {
      can: Permission.EPOS_JOB_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "receipt.index" */ '../views/eposJobs/EposJobs.vue'),
  },
  {
    path: '/sanifairLogs',
    name: 'sanifairLogs.index',
    meta: {
      can: Permission.SANIFAIR_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "receipt.index" */ '../views/sanifairLogs/SanifairLogs.vue'),
  },
  {
    path: '/automatedReports',
    name: 'automatedReports.index',
    meta: {
      can: Permission.REPORTING_VIEW,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "receipt.index" */ '../views/automatedReport/AutomatedReport.vue'),
  },
  {
    path: '/automatedReports/:id/edit',
    name: 'automatedReports.edit',
    meta: {
      auth: true,
    },
    props: {
      default: true,
      editing: true,
    },
    component: () =>
      import(/* webpackChunkName: "screensaver.edit" */ '../views/automatedReport/EditAutomatedReport.vue'),
  },
  {
    path: '/automatedReports/create',
    name: 'automatedReports.create',
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "screensaver.create" */ '../views/automatedReport/EditAutomatedReport.vue'),
  },
  {
    path: '/paypal/:id/finish',
    name: 'paypal.finish',
    meta: {
      can: Permission.VENUE_PAYPAL_AUTHORIZE,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "paypal.finish" */ '../views/paypal/PayPalSuccess.vue'),
  },

  {
    path: '/legal/:id/:type',
    name: 'legal.index',
    component: () => import(/* webpackChunkName: "legal.index" */ '../views/VenueLegal.vue'),
  },

  {
    path: '/privacy',
    name: 'privacy.index',
    component: () => import(/* webpackChunkName: "privacy.index" */ '../views/Privacy.vue'),
  },

  {
    path: '/imprint',
    name: 'imprint.index',
    component: () => import(/* webpackChunkName: "imprint.index" */ '../views/Imprint.vue'),
  },
  {
    path: '/lightspeed/success',
    name: 'lightspeed.success',
    component: () => import(/* webpackChunkName: "lightspeed.success" */ '../views/lightspeed/LightspeedSuccess.vue'),
  },
  {
    path: '/lightspeed/error',
    name: 'lightspeed.error',
    component: () => import(/* webpackChunkName: "lightspeed.error" */ '../views/lightspeed/LightspeedError.vue'),
  },
  {
    path: '/sync',
    name: 'sync.index',
    meta: {
      can: Permission.ADMIN,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "sync.index" */ '../views/sync/Sync.vue'),
  },
  {
    path: '/sync/create',
    name: 'sync.create',
    meta: {
      can: Permission.ADMIN,
      auth: true,
    },
    component: () => import(/* webpackChunkName: "sync.index" */ '../views/sync/CreateSync.vue'),
  },
  {
    path: '/aggregator-syncs',
    name: 'aggregatorSyncs.index',
    meta: {
      can: [Permission.JET_SYNC_JOB_VIEW, Permission.WOLT_SYNC_JOB_VIEW, Permission.UBER_EATS_SYNC_JOB_VIEW],
      auth: true,
    },
    component: () => import(/* webpackChunkName: "sync.index" */ '../views/aggregatorSyncs/AggregatorSyncs.vue'),
  },
  {
    path: '/order-providers/wolt/:status',
    name: 'ordersProviders.wolt.status',
    props: true,
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "ordersProviders.wolt.status" */ '../views/orderProviders/WoltIntegrationStatus.vue'),
  },
  {
    path: '/order-providers/uber-eats/:status',
    name: 'ordersProviders.uberEats.status',
    props: true,
    meta: {
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ordersProviders.uberEats.status" */ '../views/orderProviders/UberEatsIntegrationStatus.vue'
      ),
  },
  // keep it below everything
  {
    path: '/error',
    name: 'error.index',
    redirect: '/',
  },
  {
    path: '/401',
    name: 'error.forbidden',
    component: () => import(/* webpackChunkName: "error.forbidden" */ '../views/error/Forbidden.vue'),
  },
  {
    path: '/404',
    name: 'error.notFound',
    component: () => import(/* webpackChunkName: "error.NotFound" */ '../views/error/NotFound.vue'),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "error.notFound" */ '../views/error/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: any) => {
  if (to.meta?.hasOwnProperty('auth') && to.meta.auth === true && !isAuthorized()) {
    next({ name: 'start' });
    return;
  }
  if (to.meta?.hasOwnProperty('can') && !can(to.meta.can)) {
    next({ name: 'error.forbidden' });
    return;
  }
  if (to.meta?.hasOwnProperty('canAny') && !hasAnyPermission(to.meta.canAny)) {
    next({ name: 'error.forbidden' });
    return;
  }

  if (to.meta?.hasOwnProperty('isCustomerGroup') && !isCustomerGroup(to.meta.isCustomerGroup)) {
    next({ name: 'error.forbidden' });
    return;
  }
  // @ts-ignore
  if (to.meta.hasOwnProperty('useVenue') && to.meta.useVenue === true && !store.state.venue.active) {
    // @ts-ignore
    store.dispatch('dialog/show', DialogType.VenueSelector);
  }
  if (to.name === 'start' && isAuthorized() && can(Permission.STATISTICS_OVERVIEW)) {
    next({ name: 'dashboard.index' });
    return;
  }
  if (to.name === 'start' && isAuthorized()) {
    next({ name: 'home.index' });
    return;
  }

  if (from.query.page) {
    if (
      from.path !== to.path &&
      from.query.page !== to.query.page &&
      from.query.page &&
      from.name &&
      (from.name.includes('edit') || from.name.includes('create'))
    ) {
      // @ts-ignore
      next({ path: to.path, query: { page: from.query.page }, replace: true });
      return;
    }
  }

  next(true);
});

export default router;
